<template>
  <div class="contents register wedding">
    <div class="title flexB">
      <h2>이벤트 관리</h2>
    </div>
    <div class="box one full">
      <div class="inputBox">
        <div class="flexL">
          <p class="bold">
            카테고리
            <span>*</span>
          </p>
          <select class="searchCondition" v-model="category">
            <option value disabled>선택</option>
            <option value="PLACE">웨딩장소</option>
            <option value="WEDDING">웨딩패키지</option>
            <option value="PHOTO">사진</option>
            <option value="DRESS">드레스</option>
            <option value="BEAUTY">헤어/메이크업</option>
            <option value="HONEYMOON">허니문</option>
            <option value="ETC">기타</option>
          </select>
        </div>
        <div class="flexL">
          <p class="bold">
            제목
            <span>*</span>
          </p>
          <input type="text" v-model="title" />
        </div>
        <div class="flexL">
          <p class="bold">상단라벨</p>
          <el-select
            v-model="topLabel"
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="미선택" label="미선택" value=""></el-option>
            <el-option
              key="스탬프투어"
              label="스탬프투어"
              value="스탬프투어"
            ></el-option>
            <el-option
              key="단독제휴"
              label="단독제휴"
              value="단독제휴"
            ></el-option>
            <el-option
              key="플래너PICK"
              label="플래너PICK"
              value="플래너PICK"
            ></el-option>
            <el-option key="기획전" label="기획전" value="기획전"></el-option>
            <el-option
              key="타임세일"
              label="타임세일"
              value="타임세일"
            ></el-option>
          </el-select>
        </div>
        <div class="flexL">
          <p class="bold">이벤트라벨</p>
          <el-select v-model="label" placeholder="선택" class="searchCondition">
            <el-option key="미선택" label="미선택" value=""></el-option>
            <el-option key="EVENT" label="EVENT" value="EVENT"></el-option>
            <el-option key="HOT" label="HOT" value="HOT"></el-option>
            <el-option key="NEW" label="NEW" value="NEW"></el-option>
            <el-option key="BEST" label="BEST" value="BEST"></el-option>
            <el-option key="COUPON" label="COUPON" value="COUPON"></el-option>
            <el-option key="추천" label="추천" value="추천"></el-option>
            <el-option
              key="후기이벤트"
              label="후기이벤트"
              value="후기이벤트"
            ></el-option>
          </el-select>
        </div>
        <div class="flexL double">
          <p class="bold">이벤트 내용</p>
          <textarea v-model="content" cols="10" rows="10"></textarea>
        </div>
        <div class="flexL">
          <p class="bold">
            대표사진
            <span>*</span>
          </p>
          <div class="imgBox">
            <ul>
              <li v-if="thumbnail">
                <img :src="thumbnail" alt="업로드이미지" />
                <a
                  class="cancel"
                  @click="handleDeleteFile(thumbnail, 'thumbnail')"
                >
                  <img
                    src="@/assets/images/icon_svg/ic_delete_wh.svg"
                    alt="icon"
                  />
                </a>
              </li>
              <li v-else>
                <label for="imgUpload">
                  <span class="material-icons addBtn">add</span>
                </label>
                <input
                  type="file"
                  ref="thumbnail"
                  @change="sendFile('thumbnail')"
                  id="imgUpload"
                  accept="image/*"
                />
              </li>
            </ul>
            <em class="notice"
              >대표사진은 3:2 비율로 업로드 됩니다. ex) 500 * 333</em
            >
          </div>
        </div>
        <div class="flexL">
          <p class="bold">
            이벤트이미지
            <span>*</span>
          </p>
          <div class="imgBox">
            <ul>
              <li>
                <label for="imgUpload">
                  <span class="material-icons addBtn">add</span>
                </label>
                <input
                  type="file"
                  ref="images"
                  @change="sendFile('images')"
                  id="imgUpload"
                  accept="image/*"
                />
              </li>
              <li v-for="(image, i) in images" :key="i">
                <img :src="image" alt="업로드이미지" />
                <a class="cancel" @click="handleDeleteFile(image, 'images')">
                  <img
                    src="@/assets/images/icon_svg/ic_delete_wh.svg"
                    alt="icon"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="buttonWrap">
        <router-link to="/admin/eventmanage">목록</router-link>
        <button class="basic" @click="submit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
        <button v-if="editMode" class="line" @click="handleDelete">삭제</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { uploadFile, deleteFile, deletePostFile } from "@/api/file";
import {
  createEvent,
  fetchEventDetail,
  upadateEvent,
  removeEvent,
} from "@/api/admin";
export default {
  data() {
    return {
      editMode: false,
      moment: moment,
      title: "",
      eventName: "",
      category: "",
      content: "",
      thumbnail: "",
      file: "",
      images: [],
      eventId: "",
      updatedAt: "",
      createdAt: "",
      topLabel: "",
      label: "",
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "10");
  },
  mounted() {
    if (this.$route.query.id) {
      this.editMode = true;
      this.eventId = this.$route.query.id;
      this.getEvent();
    }
  },
  methods: {
    comma(str) {
      str = String(str);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    },
    uncomma(str) {
      str = String(str);
      return str.replace(/[^\d]+/g, "");
    },
    removeChar(event) {
      if (
        event.keyCode == 8 ||
        event.keyCode == 46 ||
        event.keyCode == 37 ||
        event.keyCode == 39
      )
        return;
      else {
        this.price = this.comma(
          this.uncomma(event.target.value.replace(/[^0-9]/g, ""))
        );

        event.preventDefault();
      }
    },
    getEvent() {
      fetchEventDetail(this.eventId).then((res) => {
        if (res.data.status == 200) {
          this.title = res.data.data.title;
          this.category = res.data.data.category;
          this.content = res.data.data.content;
          this.images = res.data.data.images;
          this.thumbnail = res.data.data.thumbnail;
          this.createdAt = res.data.data.createdAt;
          this.updatedAt = res.data.data.updatedAt;
          this.topLabel = res.data.data.topLabel;
          this.label = res.data.data.label;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    sendFile(type) {
      if (type == "thumbnail") {
        if (this.thumbnail) {
          this.$alert("썸네일 삭제 후 업로드 해주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
        this.file = this.$refs.thumbnail.files[0];
      } else if (type == "images") {
        this.file = this.$refs.images.files[0];
      }
      if (this.file == undefined) {
        return;
      }
      let formdata = new FormData();
      formdata.append("file", this.file);

      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            if (type == "thumbnail") {
              this.thumbnail = res.data.url;
            } else if (type == "images") {
              this.images.push(res.data.url);
            }
            this.file = "";
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
            return;
          }
        } else {
          this.$alert("서버오류 입니다. 다시 파일을 업로드 해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      });
    },
    handleDeleteFile(url, type) {
      this.$confirm("이미지를 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        if (this.eventId) {
          deletePostFile(this.eventId, url, type).then((res) => {
            if (res.data.status == 200) {
              if (type == "thumbnail") {
                this.thumbnail = "";
              } else if (type == "images") {
                this.images.splice(this.images.indexOf(url), 1);
              }
            } else {
              this.$alert(res.data.message, {
                confirmButtonText: "확인",
                center: true,
              });
            }
          });
        } else {
          deleteFile(url).then((res) => {
            if (res.data.status == 200) {
              if (type == "thumbnail") {
                this.thumbnail = "";
              } else if (type == "images") {
                this.images.splice(this.images.indexOf(url), 1);
              }
            } else {
              this.$alert(res.data.message, {
                confirmButtonText: "확인",
                center: true,
              });
            }
          });
        }
      });
    },
    handleDelete() {
      this.$confirm("삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        removeEvent(this.eventId).then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "Success",
              message: "이벤트 정보가 삭제 되었습니다.",
              type: "success",
            });
            this.$router.replace("/admin/eventmanage");
          }
        });
      });
    },
    submit() {
      if (this.category == "") {
        this.$alert("카테고리를 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.title == "") {
        this.$alert("제목을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.thumbnail == "") {
        this.$alert("대표사진을 등록해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.images == "") {
        this.$alert("상품이미지를 등록해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }

      let data = {
        eventId: this.eventId,
        title: this.title,
        category: this.category,
        content: this.content,
        images: this.images,
        thumbnail: this.thumbnail,
        topLabel: this.topLabel,
        label: this.label,
      };

      if (this.editMode) {
        upadateEvent(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("이벤트 정보가 반영 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.push("/admin/eventmanage");
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      } else {
        createEvent(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("이벤트 등록이 완료 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.push("/admin/eventmanage");
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      }
    },
  },
};
</script>
